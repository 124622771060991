import { flux } from '@/core/flux.module';
import { ImpactReportCategory, ImpactReportDashboardContent } from '@/impactReports/impactReport.types';
import { PUSH_WORKBENCH } from '@/core/flux.service';
import { addOrUpdateImpactReportConfiguration } from '@/impactReports/impactReport.utilities';
import { sqImpactReportStore } from '@/core/core.stores';

export function setImpactReportTableId(tableId: string) {
  flux.dispatch('IMPACT_REPORT_SET_TABLE_ID', { tableId });
}

export function setImpactReportCategories(categories: ImpactReportCategory[] | undefined) {
  flux.dispatch('IMPACT_REPORT_SET_CATEGORIES', { categories });
}

export function setImpactReportStartDate(startDate: Date) {
  flux.dispatch('IMPACT_REPORT_SET_START_DATE', { startDate });
}

export function setImpactReportEndDate(endDate: Date) {
  flux.dispatch('IMPACT_REPORT_SET_END_DATE', { endDate });
}

export function setValueCaptureDateRange(startDate: Date, endDate: Date) {
  setImpactReportStartDate(startDate);
  setImpactReportEndDate(endDate);
}

export function setImpactReportLayoutDefinition(layoutDefinition: ImpactReportDashboardContent[]) {
  flux.dispatch('IMPACT_REPORT_SET_LAYOUT_DEFINITION', { layoutDefinition }, PUSH_WORKBENCH);
}

export function removeImpactReportContent(i: string) {
  flux.dispatch('IMPACT_REPORT__REMOVE_CONTENT', { i }, PUSH_WORKBENCH);
}

export function setForceTableReloadFlag(forceTableReload: boolean) {
  flux.dispatch('IMPACT_REPORT_SET_FORCE_TABLE_RELOAD', { forceTableReload });
}

export function setAggregationColumn(aggregationColumn: string | undefined) {
  flux.dispatch('IMPACT_REPORT_SET_AGGREGATION_COLUMN', { aggregationColumn });
}

export function setBiggestImpact(biggestImpact: number) {
  flux.dispatch('IMPACT_REPORT_SET_BIGGEST_IMPACT', { biggestImpact });
}

export function setUseCaseCount(useCaseCount: number) {
  flux.dispatch('IMPACT_REPORT_SET_USE_CASE_COUNT', { useCaseCount });
}

export function setOverallImpact(overallImpact: number) {
  flux.dispatch('IMPACT_REPORT_SET_OVERALL_IMPACT', { overallImpact });
}

export function setFilteredImpact(filteredImpact: number) {
  flux.dispatch('IMPACT_REPORT_SET_FILTERED_IMPACT', { filteredImpact });
}

export function setDisplayValueCaptureEditModal(displayEditModal: boolean) {
  flux.dispatch('IMPACT_REPORT_SET_DISPLAY_EDIT_MODAL', { displayEditModal });
}

export async function setImpactReportShowSite(showSite: boolean) {
  await addOrUpdateImpactReportConfiguration(sqImpactReportStore.tableId!, {
    ...sqImpactReportStore.impactReportConfiguration,
    showSite,
  });
  flux.dispatch('IMPACT_REPORT_SET_SHOW_SITE', { showSite });
}

export function setImpactReportShowSiteStoreOnly(showSite: boolean) {
  flux.dispatch('IMPACT_REPORT_SET_SHOW_SITE', { showSite });
}

export async function setImpactReportShowAssetPath(showAssetPath: boolean) {
  await addOrUpdateImpactReportConfiguration(sqImpactReportStore.tableId!, {
    ...sqImpactReportStore.impactReportConfiguration,
    showAssetPath,
  });
  flux.dispatch('IMPACT_REPORT_SET_SHOW_ASSET_PATH', { showAssetPath });
}

export function setImpactReportShowAssetPathStoreOnly(showAssetPath: boolean) {
  flux.dispatch('IMPACT_REPORT_SET_SHOW_ASSET_PATH', { showAssetPath });
}
